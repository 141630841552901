import React, { Fragment } from "react";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import TextEditor from "./TextEditor";
import { API_BASE_URL } from './config';

function Events() {
  return (
    <Fragment>
      <div className="main-wrapper">
      <LeftMenu/>
      <Header/>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title"> Events Form</h5>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Events Information</h5>
                    <form action="#">
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="row">
                              <label className="col-lg-3 col-form-label">
                                Title
                              </label>
                              <div className="col-lg-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="row">
                              <label className="col-lg-3 col-form-label">
                                Date{" "}
                              </label>
                              <div className="col-lg-9">
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Title"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ marginTop: "30px", marginBottom: "10px" }}
                        >
                          <div className="col-xl-6 ">
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                Images{" "}
                              </label>
                              <div className="col-lg-9">
                                <input
                                  type="file"
                                  id="events"
                                  className="form-control"
                                  placeholder="Locations"
                                />
                                <span
                                  id="alert"
                                  style={{ color: "red" }}
                                >
                                  &nbsp;&nbsp;{" "}
                                  <strong style={{ fontSize: "12px" }}>
                                    !Required Image Size: 300kb and 400x600
                                  </strong>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-10 ">
                            <div className="form-group row">
                              <label className="col-lg-2  col-form-label">
                                Description{" "}
                              </label>
                              <div className="col-lg-10">
                                <TextEditor />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn text-light"
                          style={{ backgroundColor: "#004884" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Events List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="datatable table table-stripped">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>years</th>
                            <th>Event Name</th>

                            <th>Event Images</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>29/03/2023</td>
                            <td>Annual Day</td>

                            <td>
                              <img
                                src="https://theresaacademy.com/ktg/images/9.jpg"
                                alt="banner images"
                                width={100}
                                style={{ margin: "5px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              earning by doing/experimenting is the purpose{" "}
                              <br />
                              behind the Science laboratories, here the students{" "}
                              <br />
                              are getting answers for their "How"
                            </td>

                            <td>
                              <a href="">
                                <i className="far fa-edit me-2"></i>Edit
                              </a>
                              &nbsp;&nbsp;
                              <a href="javascript:void(0);">
                                <i className="far fa-trash-alt me-2"></i>Delete
                              </a>
                              &nbsp;&nbsp;
                              <Link to="/EventsAdd">
                                <FontAwesomeIcon icon={faCirclePlus} /> Add
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Events;
