import React, { Fragment, useState, useRef, useEffect } from "react";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import "../App.css";
import axios from "axios";
import { API_BASE_URL,API_IMAGE_URL } from './config';

function Member() {

  const [fetchError, setfetchError]=useState ({
    color: '',
    mgs: ''
  })
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({ name: '', position: '', image: null });
  const inputRef = useRef(null);
  const [button, setButton] = useState(true);
  const [tableId, setTableId] = useState(null);
  const [file, setFile] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios.get(`${API_BASE_URL}/member`)
      .then((response) => setItems(response.data))
      .catch((error) => console.error(error));
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    if (newItem.name === '' || newItem.position === '') {
      setfetchError({color: 'red', mgs: 'Please enter a required field.'});
      return;
    }
    if (newItem.image){
    const formData = new FormData();
    formData.append('name', newItem.name);
    formData.append('position', newItem.position);
    formData.append('image', newItem.image);

      try {
        const response = await axios.post(`${API_BASE_URL}/member`, formData);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        setNewItem({name: '', position: '', image: null});
        inputRef.current.value = null;
        setFile('');
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
    } else {
      setfetchError({color: 'red', mgs: 'Image file is Empty !!!'});
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/memberEdit/${id}`);
      if (response.data.length>0) {
        setNewItem({name: response.data[0].name, position: response.data[0].position});
        setTableId(response.data[0].id);
        setFile(`${API_IMAGE_URL}/${response.data[0].image}`);
        setButton(!button)
      } else {
        setfetchError({color: 'red', mgs: 'Data is Not Found'});
      }
      setTimeout(()=>{
        setfetchError({color: '', mgs: ''});
      },2000); 
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (newItem.name === '' || newItem.position === '') {
      setfetchError({color: 'red', mgs: 'Please enter a required field.'});
      return;
    }
    if (newItem.image){
    const formData = new FormData();
    formData.append('banner_title', newItem.name);
    formData.append('position', newItem.position);
    formData.append('image', newItem.image);
    formData.append('id', tableId);

      try {
        const response = await axios.put(`${API_BASE_URL}/member`, formData);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        setNewItem({name: '', position: '', image: null});
        inputRef.current.value = null;
        setFile(null);
        setButton(true);
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
    } else {
      setfetchError({color: 'red', mgs: 'Image file is Empty !!!'});
    }
  };

  const handleDelete = async (id) => {
      try {
        const response = await axios.delete(`${API_BASE_URL}/member/${id}`);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
  };

  const handleReset = (e)=>{
    e.preventDefault();
    setNewItem({name: '', position: '', image: null});
    setFile(null);
    inputRef.current.value = null;
    setButton(true);
    setfetchError({color: '', mgs: ''});
  }

  return (
    <Fragment>
      <div className="main-wrapper">
      <LeftMenu/>
      <Header/>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title"> Members Form</h5>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title"> Members Information</h5>
                    <form action="#">
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div className="row">
                          <div className="col-xl-6 mb-4">
                            <div className="row">
                              <label className="col-lg-4 col-form-label">
                                Name
                              </label>
                              <div className="col-lg-8">
                              <input
                                type="text"
                                name="MembersName"
                                className="form-control"
                                placeholder="Members Name"
                                value={newItem.name}
                                onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                              />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              Image
                            </label>
                            <div className="col-lg-9">
                              <input
                                type="file"
                                 name="image"
                                className="form-control"
                                onChange={(e) => {setNewItem({ ...newItem, image: e.target.files[0] }); setFile(URL.createObjectURL(e.target.files[0])); } }
                                ref={inputRef}
                              />
                              <span style={{ color: "red"}} >
                                <strong style={{fontSize:"12px"}}>
                                  !Required Image Size: 300kb and 400x600
                                </strong>
                              </span>
                              <img width={100} src={file} />
                            </div>
                          </div>
                        </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 mb-4">
                            <div className="row">
                              <label className="col-lg-4 col-form-label">
                                Position
                              </label>
                              <div className="col-lg-8">
                              <input
                                type="text"
                                name="position"
                                className="form-control"
                                placeholder="Position"
                                value={newItem.position}
                                onChange={(e) => setNewItem({ ...newItem, position: e.target.value })}
                              />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                      <div className="text-end">
                      <button className="btn text-light" style={{ backgroundColor: "#004884" ,     marginRight: "10px" }} onClick={handleReset} > Reset </button>
                      {button ? <button className="btn text-light" style={{ backgroundColor: "#004884" }} onClick={handleCreate} > Submit </button> : <button className="btn text-light" style={{ backgroundColor: "#004884" }} onClick={handleUpdate} > Update </button> }

                        <span style={{ color: fetchError.color}} >
                          <strong style={{fontSize:"14px"}}>
                          {fetchError.mgs && <p>{`Report : ${fetchError.mgs}`}</p>} 
                          </strong>
                       </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title"> Members List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="datatable table table-stripped">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Images</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {items.map((item, i) => (
                          <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.position}</td>
                            <td>
                               <img src={`${API_IMAGE_URL}/${item.image}`} width={100} alt={item.banner_title} />
                            </td>
                            <td>
                              <a href="javascript:void(0);" style={{color: '#00A36C'}} 
                                onClick={() => handleEdit(item.id)}
                              >
                                <i className="far fa-edit me-2"></i>Edit
                              </a>
                              &nbsp;&nbsp;
                              <a href="javascript:void(0);" style={{color: '#ff0000'}} 
                                onClick={() => handleDelete(item.id)}
                              ><i className="far fa-trash-alt me-2"></i>Delete</a>
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Member;
