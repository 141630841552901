import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import moment from 'moment';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { API_BASE_URL } from './config';

function Achievement() {

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const handleContentChange = (content) => {
    setNewItem({ ...newItem, description: content })
  };

  const [fetchError, setfetchError]=useState ({
    color: '',
    mgs: ''
  })
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({ title: '' , description: ''});
  const [button, setButton] = useState(true);
  const [tableId, setTableId] = useState(null);
  const [evenDate, setEvenDate] = useState(new Date());

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios.get(`${API_BASE_URL}/achievement`)
      .then((response) => setItems(response.data))
      .catch((error) => console.error(error));
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    if (evenDate === '' || newItem.title === '' || newItem.description === '') {
      setfetchError({color: 'red', mgs: 'Please enter a required field.'});
      return;
    }
    const formData = {
      event_date: new Date(evenDate),
      title: newItem.title,
      description: newItem.description,
    };

      try {
        const response = await axios.post(`${API_BASE_URL}/achievement`, formData);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});  
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        setNewItem({title: '', description: '' });
        setEvenDate(new Date());
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/achievementEdit/${id}`);
      if (response.data.length>0) {
        setNewItem({title: response.data[0].title, description: response.data[0].description});
        setTableId(response.data[0].id);
        setEvenDate(new Date(response.data[0].event_date));
        setButton(!button)
      } else {
        setfetchError({color: 'red', mgs: 'Data is Not Found'});
      }
      setTimeout(()=>{
        setfetchError({color: '', mgs: ''});
      },2000); 
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (evenDate === '' || newItem.title === ''|| newItem.description === '') {
      setfetchError({color: 'red', mgs: 'Please enter a required field.'});
      return;
    }
    const formData = {
      event_date: new Date(evenDate),
      title: newItem.title,
      description: newItem.description,
      id: tableId,
    };

      try {
        const response = await axios.put(`${API_BASE_URL}/achievement`, formData);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        setNewItem({title: '', description: '' });
        setEvenDate(new Date());
        setButton(true)
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
  };

  const handleDelete = async (id) => {
      try {
        const response = await axios.delete(`${API_BASE_URL}/achievement/${id}`);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
  };

  const handleReset = (e)=>{
    e.preventDefault();
    setNewItem({ title: '', description: '' });
    setEvenDate(new Date());
    setButton(true);
    setfetchError({color: '', mgs: ''});
  }

  return (
    <Fragment>
      <div className="main-wrapper">
        <LeftMenu/>
        <Header/>
        <div className="page-wrapper">
          <div className="content container-fluid ">
            <div className="row">
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title"> Achievement Form</h5>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Achievement Information</h5>
                    <form action="#">
                      <div
                        className="row"
                        style={{ marginTop: "30px", marginBottom: "30px" }}
                      >
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="row">
                              <label className="col-lg-3 col-form-label">
                                Title
                              </label>
                              <div className="col-lg-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title"
                                  value={newItem.title}
                                  onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 ">
                            <div className="row">
                              <label className="col-lg-3 col-form-label">
                                Date
                              </label>
                              <div className="col-lg-9">
                              <DatePicker className="form-control" selected={evenDate} onChange={(date) => setEvenDate(date)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-10 mt-4">
                          <div className="form-group row">
                            <label className="col-lg-2  col-form-label">
                              Description{" "}
                            </label>
                            <div className="col-lg-10">
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                placeholder="write your content ...."
                                onChange={handleContentChange}
                                value={newItem.description}
                                style={{ height: "220px", width: "450px" }}
                               >
                              </ReactQuill>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end">
                      <button className="btn text-light" style={{ backgroundColor: "#004884" ,     marginRight: "10px" }} onClick={handleReset} > Reset </button>
                      {button ? <button className="btn text-light" style={{ backgroundColor: "#004884" }} onClick={handleCreate} > Submit </button> : <button className="btn text-light" style={{ backgroundColor: "#004884" }} onClick={handleUpdate} > Update </button> }

                        <span style={{ color: fetchError.color}} >
                          <strong style={{fontSize:"14px"}}>
                          {fetchError.mgs && <p>{`Report : ${fetchError.mgs}`}</p>} 
                          </strong>
                       </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Achievement List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="datatable table table-stripped">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Achievement Title</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((item, i) => (
                          <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{moment(item.event_date).format("DD/MM/YYYY")}</td>
                            <td>
                              <a href="javascript:void(0);" style={{color: '#00A36C'}} 
                                onClick={() => handleEdit(item.id)}
                              >
                                <i className="far fa-edit me-2"></i>Edit
                              </a>
                              &nbsp;&nbsp;
                              <a href="javascript:void(0);" style={{color: '#ff0000'}} 
                                onClick={() => handleDelete(item.id)}
                              ><i className="far fa-trash-alt me-2"></i>Delete
                              </a>
                              &nbsp;&nbsp;
                              <Link to={`/AchievementsAdd/${item.id}`} >
                                <FontAwesomeIcon icon={faCirclePlus} /> Add
                              </Link>
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Achievement;
