import React, { Fragment, useState, useRef, useEffect } from "react";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css"; 
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { API_BASE_URL,API_IMAGE_URL } from './config';

function Principal() {

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const handleContentChange = (content) => {
    setNewItem({ ...newItem, description: content })
  };

  const [fetchError, setfetchError]=useState ({
    color: '',
    mgs: ''
  })
  const [newItem, setNewItem] = useState({image: null , description: ''});
  const inputRef = useRef(null);
  const [tableId, setTableId] = useState(null);
  const [file, setFile] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/principalEdit/1`);
      if (response.data.length>0) {
        setNewItem({image: null, description: response.data[0].description});
        setTableId(response.data[0].id);
        setFile(`${API_IMAGE_URL}/${response.data[0].image}`);
      } else {
        setfetchError({color: 'red', mgs: 'Data is Not Found'});
      }
      setTimeout(()=>{
        setfetchError({color: '', mgs: ''});
      },2000); 
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (newItem.description === '') {
      setfetchError({color: 'red', mgs: 'Please enter a required field.'});
      return;
    }
    if (newItem.image){
    const formData = new FormData();
    formData.append('image', newItem.image);
    formData.append('description', newItem.description);
    formData.append('id', tableId);

      try {
        const response = await axios.put(`${API_BASE_URL}/principal`, formData);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        inputRef.current.value = null;
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
    } else {
      setfetchError({color: 'red', mgs: 'Image file is Empty !!!'});
    }
  };

  return (
    <Fragment>
      <div className="main-wrapper">
      <LeftMenu/>
      <Header/>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title"> Principal Form</h5>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Principal Information</h5>
                    <form action="#">
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div className="row">
                         
                          <div className="col-xl-10 ">
                            <div className="form-group row">
                              <label className="col-lg-2 col-form-label">
                                Images{" "}
                              </label>
                              <div className="col-lg-6">
                                <input
                                  type="file"
                                  id="annoucement"
                                  className="form-control"
                                  placeholder="Locations"
                                  onChange={(e) => {setNewItem({ ...newItem, image: e.target.files[0] }); setFile(URL.createObjectURL(e.target.files[0])); } }
                                  ref={inputRef}
                                />
                                 <span style={{ color: "red"}}>
                                <strong style={{fontSize:"12px"}}>
                                  !Required Image Size: 300kb and 400x600
                                </strong>
                              </span>
                              <img width={100} src={file} />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                          <div className="col-xl-10 mt-4">
                            <div className="form-group row">
                              <label className="col-lg-2  col-form-label">
                                Description{" "}
                              </label>
                              <div className="col-lg-10">                            
                                  <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    placeholder="write your content ...."
                                    onChange={handleContentChange}
                                    value={newItem.description}
                                    style={{ height: "auto", width: "auto" }}
                                  >
                                  </ReactQuill>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>

                      </div>
                      <div className="text-end">
                        <button className="btn text-light" style={{ backgroundColor: "#004884" }} onClick={handleUpdate} > Update </button> 
                        <span style={{ color: fetchError.color}} >
                          <strong style={{fontSize:"14px"}}>
                          {fetchError.mgs && <p>{`Report : ${fetchError.mgs}`}</p>} 
                          </strong>
                       </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Principal;
