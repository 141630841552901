import React, { Fragment } from "react";
import { Link } from "react-router-dom";
function Header() {
    return (
        <div className="header header-one">

            <div className="header-left header-left-one">
                <Link to="/Index" className="logo">
                    <img src="https://theresaacademy.com/ktg/images/logo-t-1.png" alt="Logo" />
                </Link>
                <Link to="/Index" className="white-logo">
                    <img src="assets/img/logo-white.png" alt="Logo" />
                </Link>
                <Link to="/Index" className="logo logo-small">
                    <img src="https://theresaacademy.com/ktg/images/logo-t-1.png" alt="Logo" width="30" height="60" />
                </Link>
            </div>

            <a href="javascript:void(0);" id="toggle_btn">
                <i className="fas fa-bars"></i>
            </a>

            <a className="mobile_btn" id="mobile_btn">
                <i className="fas fa-bars"></i>
            </a>
        </div>
    )
}

export default Header;