import React, { Fragment, useState, useEffect } from "react";
import { Link , NavLink  } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faMicrophoneLines,
  faHandHoldingMedical,
  faImages,
  faFeather,
  faHouse,
  faVideo,
  faRightFromBracket,
  faAward,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

function LeftMenu() {
 
  const [activeMenu, setActiveMenu] = useState(JSON.parse(localStorage.getItem("menuname")) || 'Banner');

  const [isOpen, setOpen] = useState(false);

  const handleClick = (menuname) => {
    setActiveMenu(menuname);
    localStorage.setItem("menuname", JSON.stringify(menuname))
    menuname === 'Settings' ? setOpen(!isOpen) : setOpen(false);
  };

  const menus = [
    {
      name: 'Banner',
      link: '/Banner',
      icon: faImage,
    },
    {
      name: 'Event',
      link: '/Announcementevent',
      icon: faFeather,
    },
    {
      name: 'Facility',
      link: '/Facility',
      icon: faHandHoldingMedical,
    },
    {
      name: 'Gallery',
      link: '/Gallery',
      icon: faImages,
    },
    {
      name: 'Video Gallery',
      link: '/Video',
      icon: faVideo,
    },
    {
      name: 'Achievement',
      link: '/Achievement',
      icon: faAward,
    },
    {
      name: 'Settings',
      link: '',
      icon: faGear,
      submenus: [
        {
          name: 'History',
          link: '/History',
        },
        {
          name: 'Chairman',
          link: '/Chairman',
        },
        {
          name: 'Principal',
          link: '/Principal',
        },
        {
          name: 'Member',
          link: '/Member',
        },
        {
          name: 'Social Media',
          link: '/Contact',
        },
        {
          name: 'Contact Us',
          link: '/ContactUs',
        },
      ],
    },
    {
      name: 'Log out',
      link: '/',
      icon: faRightFromBracket,
    },
  ];

  return (

    // scrollbar
    <div className="sidebar  style-2" id="sidebar">
      <div className="slimScrollDiv">
        <div className="sidebar-inner slimscroll">
        
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="ul" >
              <li className="menu-title">
                <span>Main</span>
              </li>
              {menus.map((menu) => ( 
                <li 
                  key={menu.name}
                  className={activeMenu === menu.name ? 'active submenu' : 'submenu'}
                  onClick={(e) => handleClick(menu.name)}
                >
                  <NavLink to={menu.link} className={menu.submenus ? 'subdrop' : ''}>
                        <FontAwesomeIcon
                          icon={menu.icon}
                          style={{ width: "18px", height: "18px" }}
                        />
                    <span>{menu.name}</span>
                    <span className={menu.submenus ? 'menu-arrow rotate' : ''}></span>
                  </NavLink>
                  {menu.submenus && (
                  <ul className={`dropdown-body ${isOpen && 'open'}`}>
                      {menu.submenus.map((submenu) => (
                        <li 
                          key={submenu.name}
                          className={activeMenu === submenu.name ? 'active dropdown-item' : 'dropdown-item'}
                          onClick={(e) => handleClick(submenu.name)}
                        >
                          <NavLink to={submenu.link}><span>{submenu.name}</span></NavLink>
                        </li>
                      ))}
                  </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftMenu;
