import './App.css';
import Index from './pages/Index';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Announcementevent from './pages/Announcementevent';
import Facility from './pages/Facility';
import History from './pages/History';
import Chairman from './pages/Chairman';
import Principal from './pages/Principal';
import Contact from './pages/Contact';
import ContactUs from './pages/ContactUs';
import Member from './pages/Member';
import Banner from './pages/Banner';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Achievement from "./pages/Achievement";
import AchievementsAdd from "./pages/AchievementsAdd";
import EventsAdd from './pages/EventsAdd';
import Video from './pages/Video';
function App() {
  return (
    <div className="App">
      <Router basename={'/'}>
        <Routes>
           <Route path='/Index' element={<Index/>}/>
           <Route path='/Video' element={<Video/>} />
           <Route path='/Announcementevent' element={<Announcementevent/>}/>
           <Route path='/Facility' element={<Facility/>}/>
           <Route path='/History' element={<History/>}/>
           <Route path='/Chairman' element={<Chairman/>}/>
           <Route path='/Principal' element={<Principal/>}/>
           <Route path='/Member' element={<Member/>}/>
           <Route path='/Contact' element={<Contact/>}/>
           <Route path='/ContactUs' element={<ContactUs/>}/>
           <Route path='/Banner' element={<Banner/>}/>
           <Route path='/Events' element={<Events/>} />
           <Route path='/EventsAdd/:mid' element={<EventsAdd/>} />
           <Route path='/AchievementsAdd/:mid' element={<AchievementsAdd/>} />
           <Route path='/Achievement' element={<Achievement/>}/>
           <Route path='/Gallery' element={<Gallery/>}/>
           <Route path='*' element={<Navigate to='/Index'/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
