import React, { Fragment , useState, useEffect  } from "react";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye';
import axios from "axios";
import { API_BASE_URL } from './config';

function Index() {
const [type, setType] = useState('password');
const [icon, setIcon] = useState(eyeOff);
const [isLoggedIn, setIsLoggedIn] = useState(false);
const [userName, setUserName] = useState('');
const [password, setPassword] = useState('');
const [fetchError, setfetchError]=useState ({
  color: '',
  mgs: ''
})

const handleToggle = () => {
  if (type==='password'){
     setIcon(eye);
     setType('text')
  } else {
     setIcon(eyeOff)
     setType('password')
  }
}

const handleSubmit = async (e) => {
  e.preventDefault();

  if (userName === '' || password === '') {
    setfetchError({color: 'red', mgs: 'Please enter a username and password.'});
    return;
  }

  try {
    const response = await axios.post(`${API_BASE_URL}/login`, {
      userName,
      password
    });
    if (response.data.success) {
      setIsLoggedIn(true);
    } else {
      setfetchError({color: 'red', mgs: response.data.message});
    }
    setTimeout(()=>{
      setfetchError({color: '', mgs: ''});
    },2000); 
  } catch (error) {
    console.error(error);
  }
};
useEffect(() => {
  if (isLoggedIn) {
    window.location.href = '/banner';
    localStorage.setItem("menuname", JSON.stringify('Banner'))
  }
}, [isLoggedIn]);

  return (
    <Fragment>
      <div className="main-wrapper login-body ">
        <div className="login-wrapper  ">
          <div className="container">
            <img
              className="img-fluid logo-dark "
              src="https://theresaacademy.com/ktg/images/logo-t-1.png"
              alt="Logo"
            />
            <div className="loginbox shadow-lg">
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Login</h1>
                  <p className="account-subtitle">Access to our dashboard</p>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="form-control-label">
                       User Name
                      </label>
                      <input type="text" name="user_name" className="form-control"
                       value={userName}  
                       onChange={(e) => setUserName(e.target.value)} 
                       placeholder="User Name"/>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Password</label>
                      <div className="pass-group">
                        <input
                          type={type}
                          name="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete="current-password"
                          className="form-control pass-input"
                        />
                        <span onClick={handleToggle} className="toggle-password">
                          <Icon class="absolute mr-10" icon={icon} size={20}/>
                        </span>
                      </div>
                    </div>
                    <button
                     type="submit"
                      className="btn btn-lg btn-block  w-100 text-light" style={{backgroundColor:"#004884"}}
                    >
                      Sign In
                    </button>
                    <span style={{ color: fetchError.color}} >
                      <strong style={{fontSize:"14px"}}>
                      {fetchError.mgs && <p>{`Report : ${fetchError.mgs}`}</p>} 
                      </strong>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Index;
