import React, { Fragment, useState, useRef, useEffect } from "react";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import axios from "axios";
import { API_BASE_URL } from './config';

function Contact() {

  const [fetchError, setfetchError]=useState ({
    color: '',
    mgs: ''
  })
  const [newItem, setNewItem] = useState({email: '' , phone: '' , address: ''});
  const [tableId, setTableId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/contactEdit/1`);
      if (response.data.length>0) {
        setNewItem({email: response.data[0].email, phone: response.data[0].phone, address: response.data[0].address});
        setTableId(response.data[0].id);
      } else {
        setfetchError({color: 'red', mgs: 'Data is Not Found'});
      }
      setTimeout(()=>{
        setfetchError({color: '', mgs: ''});
      },2000); 
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (newItem.email === '' || newItem.phone === '' || newItem.address === '') {
      setfetchError({color: 'red', mgs: 'Please enter a required field.'});
      return;
    }
    const formData = {
      email: newItem.email,
      phone: newItem.phone,
      address: newItem.address,
      id: tableId,
    };

      try {
        const response = await axios.put(`${API_BASE_URL}/contact`, formData);
        if (response.data.success) {
          setfetchError({color: 'green', mgs: response.data.message});
          fetchData();
        } else {
          setfetchError({color: 'red', mgs: response.data.message});
        }
        setTimeout(()=>{
          setfetchError({color: '', mgs: ''});
        },2000); 
      } catch (error) {
        console.error(error);
      }
  };

  return (
    <Fragment>
      <div className="main-wrapper">
      <LeftMenu/>
      <Header/>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-header ">
                    <h5 className="card-title"> Contactus Form</h5>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Contactus Information</h5>
                    <form action="#">
                      <div
                        className="row"
                        style={{ marginTop: "30px", marginBottom: "30px" }}
                      >
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="row">
                              <label className="col-lg-3 col-form-label">
                                Email
                              </label>
                              <div className="col-lg-9">
                              <input
                                  type="email"
                                  className="form-control"
                                  placeholder="email"
                                  value={newItem.email}
                                  onChange={(e) => setNewItem({ ...newItem, email: e.target.value })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="row">
                              <label className="col-lg-3 col-form-label">
                                Phone
                              </label>
                              <div className="col-lg-9">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="phone"
                                  value={newItem.phone}
                                  onChange={(e) => setNewItem({ ...newItem, phone: e.target.value })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-xl-6">
                            <div className="row">
                              <label className="col-lg-3 col-form-label">
                                Address
                              </label>
                              <div className="col-lg-9">
                                <textarea
                                  type="Text"
                                  className="form-control"
                                  placeholder="Address "
                                  value={newItem.address}
                                  style={{height: '130px'}}
                                  onChange={(e) => setNewItem({ ...newItem, address: e.target.value })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end">
                        <button className="btn text-light" style={{ backgroundColor: "#004884" }} onClick={handleUpdate} > Update </button> 
                        <span style={{ color: fetchError.color}} >
                          <strong style={{fontSize:"14px"}}>
                          {fetchError.mgs && <p>{`Report : ${fetchError.mgs}`}</p>} 
                          </strong>
                       </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Contact;
